<template>
  <div class="wrapper-content">
    <div class="detail">
      <div class="detail-image">
        <el-image :src="loadUrl(trainInfo.image)" fit="cover"><div slot="placeholder" class="image-slot">加载中<span class="dot">...</span></div></el-image>
      </div>
      <div class="detail-right">
        <h2>
          {{ trainInfo.name }}
          <el-tag v-if="trainInfo.status == 5" type="success" effect="dark" size="mini">筹备中</el-tag>
          <el-tag v-if="trainInfo.status == 7" type="danger" effect="dark" size="mini">报名中</el-tag>
          <el-tag v-if="trainInfo.status == 8" type="info" effect="dark" size="mini">报名已结束</el-tag>
          <el-tag v-if="trainInfo.status == 9" effect="dark" size="mini">{{trainInfo.category==1?'活动':'培训'}}中</el-tag>
          <el-tag v-if="trainInfo.status == 10" type="info" effect="dark" size="mini">活动已结束</el-tag>
        </h2>
        <div class="detail-right-textbg">
          <p>
            <span class="name">{{trainInfo.category==1?'活动':'培训'}}时间：</span>
            <span class="value">{{ formatDateYMD(trainInfo.hostStartTime) }}-{{ formatDateYMD(trainInfo.hostEndTime) }}</span>
          </p>
          <p>
            <span class="name">{{trainInfo.category==1?'活动':'培训'}}地点：</span>
            <span class="value">{{trainInfo.province}}{{trainInfo.city}}{{trainInfo.area}}{{trainInfo.address}}</span>
          </p>
<!--          <p>-->
<!--            <span class="name">培训方式：</span>-->
<!--            <span class="value" v-if="trainInfo.mode==1">线下培训</span>-->
<!--            <span class="value" v-else-if="trainInfo.mode == 2">{{ trainInfo.onlineModel == 1 ? "视频培训" : "直播培训"  }}</span>-->
<!--          </p>-->
          <p>
            <span class="name">报名费用：</span>
            <span class="value red fz22" v-if="trainInfo.collectFee == 1">
              <i class="f12">¥</i>{{ trainInfo.totalFee }}
            </span>
            <span class="red" v-else>免费</span>
          </p>
        </div>
        <div class="detail-right-text">
          <p>
            <span class="name">报名时间：</span>
            <span class="value">{{ formatDateYMD(trainInfo.signStartTime) }}-{{ formatDateYMD(trainInfo.signEndTime) }}</span>
          </p>
          <template v-if="trainInfo.trainCompanyVos && trainInfo.trainCompanyVos.length > 0">
            <p v-for="(item,index) in trainInfoUnits" :key="index">
              <span class="name">{{item.title}}：</span>
              <span class="value">{{ item.content}}</span>
            </p>
          </template>
        </div>
        <template v-if="trainInfo.toMember==1">
          <el-button type="primary" @click="signup" :disabled="trainInfo.status != 7 || !isMember">{{trainInfo.category==1?'活动':'培训'}}报名</el-button>
          <span class="vipIcon" v-if="trainInfo.toMember==1">(注：<img src="../../assets/image/vip-crownl.png" height="200" width="200"/>仅限会员报名)</span>
        </template>
        <template v-else>
          <el-button type="primary" @click="signup" :disabled="trainInfo.status != 7">{{trainInfo.category==1?'活动':'培训'}}报名</el-button>
        </template>
      </div>
    </div>

    <el-tabs v-model="activeName" @tab-click="handleClick" class="detail-tab">
      <el-tab-pane v-for="(item, index) in trainInfo.trainExplainVos" :key="index" :label="item.title" :name="index.toString()">
        <div class="ql-editor" v-html="text"></div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { trainTrainInfo } from "@/api/train.js";
import { getTextarea } from "@/api/events";
import {memberIsMember} from "../../api/partners";
export default {
  name: "train-detail",
  components: {},
  props: {
    id: String,
  },
  data() {
    return {
      activeName: "0",
      trainInfo: {},
      files: [
        {
          fileName: "",
          filePath: "",
          fileType: "",
          id: "",
          trainId: "",
        },
      ],
      prices: [],
      trainInfoUnits: [],
      btnStatus: "",
      applytime: 0,
      applyData: {},
      preid: "",
      bigDialogVisible: false,
      text:'',
      isMember:false
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const self = this;
      self.$store.commit("showLoading");
      trainTrainInfo(this.id).then((res) => {
        if (res.code == 200) {
          this.trainInfo = res.data;
          this.getMemberIsMember(res.data.tenantId)
          self.$nextTick(()=>{
            if(this.trainInfo.trainExplainVos[0]){
              this.getTextareaFun(this.trainInfo.trainExplainVos[0].content)
            }
          })
        } else {
          self.$message(res.msg);
        }
        self.$store.commit("hideLoading");
      }).catch(()=>{
        self.$message('请求出错，请重新刷新页面！');
        self.$store.commit("hideLoading");
      });
    },

    //查询当前用户是否为会员
    getMemberIsMember(tenantId){
      let userId = window.localStorage.getItem("user")&&JSON.parse(window.localStorage.getItem("user")).userInfo.userId || "";
      memberIsMember(tenantId,userId).then(res=>{
        if(res.code == 200){
          this.isMember = res.data
        }
      })
    },
    handleClick(tab) {
      if(this.trainInfo.trainExplainVos[tab.index]){
        this.getTextareaFun(this.trainInfo.trainExplainVos[tab.index].content)
      }
    },
    //获取详情
    getTextareaFun(journalisContent){
      let params = { "journalisContent": journalisContent }
      getTextarea(params).then(res =>{
        if(res.code == 200){
          this.text = res.data.dataHtml
        }
      })
    },
    // 去报名
    signup() {
      // let _trainInfo = JSON.stringify(this.trainInfo);
      // let _prices = JSON.stringify(this.prices);
      // this.$router.push({
      //   path: "/train-signup",
      //   query: { trainInfo: _trainInfo },
      // });
      this.$router.push({path:"/train-signup",query:{id:this.trainInfo.id}})
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 44px;
  .detail-image {
    flex-shrink: 0;
    width: 386px;
    height: 283px;
    margin-right: 30px;
    .el-image {
      width: 100%;
      height: 100%;
    }
  }
  .detail-right {
    flex: 1;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #555555;
    h2 {
      height: 22px;
      line-height: 22px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      span {
        vertical-align: top;
      }
    }
  }

  .detail-right-text,
  .detail-right-textbg {
    padding: 4px 8px;
    line-height: 26px;
    margin-bottom: 10px;
    p {
      display: flex;
      align-items: start;
    }
    .name {
      flex-shrink: 0;
      //width: 70px;
      text-align: right;
      color: #999;
    }
    .value {
      // flex: 1;
    }
    .mPrice {
      color: #dd9700;
      font-size: 14px;
      flex: 1;
    }
    .red {
      color: #ff1d00;
    }
    .fz22 {
      font-size: 22px;
    }
    i.f12 {
      font-size: 12px;
    }
    i.f10 {
      font-size: 10px;
    }

    .discount {
      //display: flex;
      //align-items: center;
      //justify-content: center;
      padding: 0 10px;
      min-width: 110px;
      height: 22px;
      background: #FF8001;
      border-radius: 11px;
      color: #fff;
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .detail-right-textbg {
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 31px;
    background: url("../../assets/image/detail-textbg.png") center 100%;
  }
}
.detail-tab {
  width: 100%;
  margin: 40px 0;
  min-height: 300px;
  color: #555;
  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #e8e8e8;
  }
}

// 弹出层
.certificationDialog {
  .bold {
    font-weight: bold;
  }
  /deep/ .el-form-item__label {
    text-align: left;
  }
  /deep/ .el-dialog {
    width: 586px;
    box-shadow: 0px 9px 40px 0px rgba(75, 75, 75, 0.12);
    border-radius: 10px;
  }
  /deep/ .el-dialog__header {
    text-align: left;
    font-size: 18px;
    color: #333333;
    padding-bottom: 18px;
    border-bottom: solid 1px #e5e5e5;
  }
}

.confirmation-info {
  line-height: 42px;
  font-size: 14px;
  font-weight: 400;
  color: #555555;
}
.vipIcon{
  vertical-align: bottom;
  margin-left: 10px;
  color: #00A384;
  font-weight: bold;
  img{
    width: 20px;
    height: 20px;
  }
}
</style>
